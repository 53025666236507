<script setup>
import { useWebStore } from '@/store/web.js'
import SocialMedia from "@/components/SocialMedia.vue";

const store = useWebStore();


const columns= [
        {
          title: 'email',
          items: [
            { text: 'info@poliol.hu' },
            { text: 'sales@poliol.hu' }
          ]
        },
        {
          title: store.getLocaleString('fc2'),
          items: [
            { text: store.getLocaleString('fc3') },
            { text: '+36 20 342 2988', tel: '+36203422988' }
          ]
        },
        {
          title: store.getLocaleString('fc4'),
          items: [
            { text: store.getLocaleString('fc5') },
            { text: '+36 20 485 0585', tel: '+36204850585' }
          ]
        },
        {
          title: store.getLocaleString('fc6'),
          items: [
            { text: store.getLocaleString('fc7') },
            { text: '+36 30 075 9259', tel: '+36300759259' }
          ]
        },
        {
          title: store.getLocaleString('fc8'),
          items: [
            { text: '+36 76 448 544', tel: '+3676448544' }
          ]
        },
        {
          title: store.getLocaleString('fc10'),
          items: [
            { text: 'Poliol Gépgyártó és Csomagolástechnikai Kft.\nH-6065 Lakitelek, Tiszakécskei út 3.' }
          ]
        }
      ]
</script>

<template>
  <div class="bg-[#15141c] w-full relative">
    <div class="mx-auto pt-5 text-left text-[#c1c1c1] sm:pt-20 md:w-[90%] lg:w-full">
      <h2 class="text-white text-4xl p-2 font-bold uppercase mb-8 tracking-tighter font-['Space-Grotesk']">
        {{ store.getLocaleString('fc1') }}
      </h2>
      <div class="p-1 grid sm:grid-cols-2 lg:grid-cols-4 gap-10 lg:gap-16 pb-8">
        <div v-for="(column, index) in columns" :key="index" :class="{'col-span-2': index === columns.length - 1}">
          <h5 class="text-orange-500 uppercase text-lg font-bold mb-2">
            {{ column.title }}
          </h5>
          <h6 v-for="(item, i) in column.items" :key="i" class="text-lg m-0 whitespace-pre-wrap lg:text-xl" :class="{'pt-2': i > 0}">
            <a v-if="item.tel" :href="`tel:${item.tel}`" class="text-[#c1c1c1] no-underline">{{ item.text }}</a>
            <span class="w-full" v-else>{{ item.text }}</span>
          </h6>
        </div>
      </div>
      <social-media class="my-5 mx-auto"/>
      <UButton @click="store.showGdpr" icon="i-heroicons-document-text-solid" size="lg" class="relative sm:right-10 sm:top-10 text-lg sm:absolute" variant="outline">{{ store.getLocaleString('fc11') }}</UButton>
      <p class="mt-5 whitespace-nowrap w-min min-w-min mx-auto">{{ store.getLocaleString('fc9') }}</p>
    </div>
  </div>
</template>
